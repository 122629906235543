<template>
  <el-form ref="form" :model="formData" label-width="120px">
    <el-form-item label="默认上传方式">
      <el-radio-group v-model="formData.config_data.default">
        <el-radio label="qiniu">七牛云</el-radio>
      </el-radio-group>
    </el-form-item>

    <el-form-item label="存储空间bucket">
      <el-input size="small" placeholder="" v-model="formData.config_data.engine.qiniu.bucket"></el-input>
    </el-form-item>

    <el-form-item label="accessKey">
      <el-input size="small" placeholder="" v-model="formData.config_data.engine.qiniu.access_key"></el-input>
    </el-form-item>
    <el-form-item label="secretKey">
      <el-input size="small" placeholder="" v-model="formData.config_data.engine.qiniu.secret_key"></el-input>
    </el-form-item>
    <el-form-item label="上传地址">
      <el-input size="small" placeholder="" v-model="formData.config_data.engine.qiniu.qiniu_url"></el-input>
    </el-form-item>
    <el-form-item label="访问前缀">
      <el-input size="small" placeholder="" v-model="formData.config_data.engine.qiniu.qiniu_addr"></el-input>
    </el-form-item>
    <el-form-item label="">
      <el-button type="" size="small" @click="getStorageDetail">取 消</el-button>
      <el-button type="primary" size="small" @click="save">保 存</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { getStorageDetailAPI, saveStorageAPI } from './api'
export default {
  name: 'Storage',

  data() {
    return {
      formData: {
        key: 'storage',
        config_data: {
          default: 'qiniu',
          engine: {
            qiniu: {
              bucket: 'court-file',
              access_key: 'Dq7zqfBP5cp5Kku6ZbHyxS4fTjNRAN1FR-Ifhtnw',
              secret_key: 'DkjRe7nr_fTRk91UgGPo3dRBDY-8YKB-DuM1-q4c',
              domains: 'rmao0rd3x.hn-bkt.clouddn.com',
              domain: 'https://api.mini.sowingrass.com/zhanyi_file_hyper',
              qiniu_url: 'https://up-z2.qiniup.com',
              qiniu_addr: 'https://api.mini.sowingrass.com/law_file'
            },
            aliyun: {
              bucket: 'sowingrass',
              access_key_id: 'LTAI4G4TMvkDiCjReb3QVU89',
              access_key_secret: 'Jk5ideMhe2pYa5l3k6zmQSpAJtuet8',
              endpoint: 'oss-cn-hangzhou.aliyuncs.com',
              domain: 'https://zhongcao-static.sowingrass.com'
            },
            qcloud: {
              bucket: '',
              region: '',
              secret_id: '',
              secret_key: '',
              domain: 'http://'
            },
            local: {
              domain: 'http://127.0.0.1:9503'
            },
            minio: []
          }
        }
      }
    }
  },

  mounted() {
    this.getStorageDetail()
  },

  methods: {
    save() {
      saveStorageAPI(this.formData).then(() => {
        this.$message.success('保存成功')
        this.getStorageDetail()
      })
    },
    async getStorageDetail() {
      this.formData = await getStorageDetailAPI('storage')
    }
  }
}
</script>

<style lang="scss" scoped></style>
