<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-tabs v-model="activeName" >
      <!-- <el-tab-pane label="站点设置" name="site"></el-tab-pane> -->
      <!-- <el-tab-pane label="存储设置" name="storage"></el-tab-pane> -->
    </el-tabs>
    <Site v-if="activeName == 'site'"></Site>
    <Storage v-if="activeName == 'storage'"></Storage>
  </div>
</template>

<script>
import Storage from './modules/storage.vue'
import Site from './modules/site.vue'
export default {
  name: 'List',
  components: { Storage, Site },
  data() {
    return {
      activeName: 'site'
    }
  },

  mounted() {},

  methods: {}
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
}
</style>
